import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Message } from '../../models/message.model';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.css'],
})
export class MessageListComponent {
  @Input() messages: Message[] = [];
  @Output() actionTriggered = new EventEmitter<{
    action: string;
    data?: any;
  }>();

  onActionTriggered(event: { action: string; data?: any }): void {
    this.actionTriggered.emit(event); // Propagate the event to the parent
  }
}
