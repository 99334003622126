import {
  Component,
  Renderer2,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Input,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ChatHelperService } from '../../services/chat-helper.service';
import { marked } from 'marked';
import { v4 as uuidv4 } from 'uuid';
import { interval, NotFoundError, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Message } from '../../models/message.model';
import { SharedService } from '../../services/shared.service';

declare var bootstrap: any;

interface SearchResult {
  role: string;
  content: string;
  refusal?: string;
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent {
  showGuestPopup: boolean = false;
  guestPopupMessage: string = '';
  guestTargetRoute: string = '/chat';
  showFilterPopup: boolean = false;
  showComparePopup: boolean = false;
  sentMessage: string = '';
  showDialogBox: boolean = false;
  loading: boolean = false;
  activeUUID: string | null = null;
  selectedCourts: string[] = [];
  private messageCounter: number = 0;
  private readonly messageThreshold: number = 5;
  showSmallScreenButtons = false;
  isDocChat = false;
  isCompareCase = false;

  publicResDone: boolean = false;
  apiResDone: boolean = false;

  @Input() opinionId: number | null = null;
  @Input() caseIds: number[] = [];

  private filterSubscription!: Subscription;
  private comparePopupSubscription!: Subscription;

  @ViewChild('scrollContainer') private scrollContainer: ElementRef | null =
    null;

  messages: Message[] = [];

  constructor(
    private apiService: ApiService,
    private chatHelper: ChatHelperService,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.handleChatInit();
    this.handleAuth();
    this.loadStateFromStorage();
  }

  ngAfterViewInit(): void {
    this.chatHelper.triggerTooltips();
  }

  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
    this.comparePopupSubscription.unsubscribe();
  }

  // ----------------------------------
  // ------- Methods for ngInit -------
  // ----------------------------------

  handleChatInit() {
    if (this.opinionId) {
      this.isDocChat = true;
    }
    if (this.caseIds.length >= 2) {
      this.isCompareCase = true;
      this.applyRevealEffectCompare(this.caseIds);
    }
    this.handleSubscriptions();
  }

  handleSubscriptions() {
    this.filterSubscription = this.sharedService.filterVisibility$.subscribe(
      (visible) => {
        this.showFilterPopup = visible;
      }
    );
    this.comparePopupSubscription =
      this.sharedService.comparePopupVisibility$.subscribe((visible) => {
        this.showComparePopup = visible;
      });
  }

  handleAuth() {
    const currentUrl = this.router.url;
    if (
      localStorage.getItem('authToken') &&
      !currentUrl.includes('/document-chat') &&
      !currentUrl.includes('/compare-cases')
    ) {
      this.loadPreviousMessages();
    } else if (
      !localStorage.getItem('authToken') &&
      !currentUrl.includes('/document-chat') &&
      !currentUrl.includes('/compare-cases')
    ) {
      // this.handleGuestPopup();

      this.openGuestPopup(
        'You must sign in to Docketchat to access the history features.',
        '/chat'
      );
    }
  }

  // ----------------------------------
  // ----- Methods to handle chat -----
  // ----------------------------------

  onMessageReceived(messageText: string) {
    const uuid = uuidv4();
    const newMessage = new Message(messageText, '', true, uuid, '', false);
    this.messages.push(newMessage);
    this.activeUUID = uuid;
    const payload = {
      courts: this.selectedCourts,
      opinionId: this.opinionId,
    };

    if (!localStorage.getItem('authToken')) {
      this.messageCounter++;

      if (this.messageCounter >= this.messageThreshold) {
        // this.handleGuestPopup();
        this.openGuestPopup(
          'You must sign in to Docketchat to access the history features.',
          '/chat'
        );
        this.messageCounter = 0;
      }
    }

    this.chatHelper.scrollToBottom(this.scrollContainer);
    this.handleCompletion(messageText, uuid, payload);
  }

  handleCompletion(
    prompt: string,
    messageUUID: string,
    payload: {
      courts: string[];
    }
  ) {
    this.loading = true;
    const timeoutDuration = 60000;
    const timeoutId = setTimeout(
      () => this.handleTimeout(messageUUID),
      timeoutDuration
    );

    this.isDocChat
      ? this.sendDocChatRequest(prompt, messageUUID, payload, timeoutId)
      : this.sendRegularChatRequest(prompt, messageUUID, payload, timeoutId);
  }

  sendRegularChatRequest(
    prompt: string,
    messageUUID: string,
    payload: any,
    timeoutId: any
  ) {
    this.apiService
      .postCompletionAPI({ prompt, uuid: messageUUID, ...payload })
      .subscribe(
        (data: any) =>
          this.handleApiResponse(data, messageUUID, timeoutId, false),
        (error: any) => this.handleApiError(error, messageUUID, timeoutId)
      );
  }

  sendDocChatRequest(
    prompt: string,
    messageUUID: string,
    payload: any,
    timeoutId: any
  ) {
    this.apiService
      .postCompletionAPIdoc({ prompt, uuid: messageUUID, ...payload })
      .subscribe(
        (data: any) =>
          this.handleApiResponse(data, messageUUID, timeoutId, true),
        (error: any) => this.handleApiError(error, messageUUID, timeoutId)
      );
  }

  handleTimeout(messageUUID: string) {
    const message = this.messages.find((m) => m.uuid === messageUUID);
    if (message && message.loading) {
      message.resultFromBackend = 'Request timed out.';
      this.loading = false;
      this.activeUUID = null;
      this.cdRef.detectChanges();
      this.chatHelper.scrollToBottom(this.scrollContainer);
    }
  }

  handleApiError(error: any, messageUUID: string, timeoutId: any) {
    clearTimeout(timeoutId);
    const message = this.messages.find((m) => m.uuid === messageUUID);
    if (message) {
      message.messageType = 'error';
      message.resultFromBackend = 'An error occurred. Please try again.';
      message.loading = false;
      this.loading = false;
    }
  }

  handleApiResponse(
    data: any,
    messageUUID: string,
    timeoutId: any,
    isDocChat: boolean
  ) {
    console.log('data ', data);
    clearTimeout(timeoutId);
    const message = this.messages.find((m) => m.uuid === messageUUID);
    console.log(this.messages);

    if (message) {
      message.loading = false;
      message.resultFromBackend = data;
      message.messageType = isDocChat ? 'document' : 'regular';
    } else {
      console.log('no message found');
    }
    this.chatHelper.triggerTooltips();
    this.loading = false;
  }

  startNewChat(): void {
    this.chatHelper.startNewChat();
  }

  stopGeneration() {
    const activeMessage = this.messages.find((m) => m.uuid === this.activeUUID);

    if (activeMessage && this.loading === true) {
      this.loading = false;
      if (activeMessage.resultFromBackend == '') {
        activeMessage.stopped = true;
      }
      this.activeUUID = null;
      this.cdRef.detectChanges();
    }
  }

  handleSendClick() {
    if (this.loading) {
      this.stopGeneration();
    } else {
      this.onMessageReceived(this.sentMessage);
    }
  }

  handleCardClick(cardText: string) {
    this.onMessageReceived(cardText);
  }

  //-------------------------------------
  //----------- Pop-up Methods ----------
  //-------------------------------------

  // handleGuestPopup() {
  //   this.showGuestPopup = !this.showGuestPopup;
  // }

  openGuestPopup(message: string, targetRoute: string): void {
    this.guestPopupMessage = message;
    this.guestTargetRoute = targetRoute;
    console.log('guesttargetRoute: ', this.guestTargetRoute);
    this.handleGuestPopup();
  }

  handleGuestPopup() {
    this.showGuestPopup = !this.showGuestPopup;
  }

  login(targetRoute: string = '/chat'): void {
    const loginUrl = this.router.serializeUrl(
      this.router.createUrlTree(['/login'], {
        queryParams: { redirect: targetRoute },
      })
    );
    window.open(loginUrl, '_blank');
    this.handleGuestPopup();
  }

  toggleFilter() {
    if (localStorage.getItem('authToken'))
      this.sharedService.toggleFilterVisibility();
    else
      this.openGuestPopup('You should sign in to filter the courts.', '/chat');
  }

  toggleComparePopup() {
    this.sharedService.toggleComparePopupVisibility();
  }

  // ------------------------------------
  // ---------- Resize Methods ----------
  // ------------------------------------

  handleSmallScreenButtons() {
    this.showSmallScreenButtons = !this.showSmallScreenButtons;
  }

  // ------------------------------------
  // --------- Filter Settings ----------
  // ------------------------------------

  onSelectedCourtsChange(selectedCourts: string[]) {
    this.selectedCourts = selectedCourts;
    // localStorage.setItem('selectedCourts', JSON.stringify(this.selectedCourts));
    this.apiService.updateUserCourts(this.selectedCourts).subscribe(
      (response) => {
        console.log('Update successful:', response);
      },
      (error) => {
        console.error('Error updating courts:', error);
      }
    );
    console.log('Updated selected courts:', this.selectedCourts);
  }

  // loadStateFromStorage() {
  //   const savedCourts = localStorage.getItem('selectedCourts');

  //   if (savedCourts) {
  //     this.selectedCourts = JSON.parse(savedCourts);
  //   }
  // }

  loadStateFromStorage() {
    this.apiService.getUserCourts().subscribe(
      (savedCourts) => {
        console.log('Fetched courts:', savedCourts);
        this.selectedCourts = savedCourts; // Directly assign the array (no need for JSON.parse)
      },
      (error) => {
        console.error('Error fetching user courts:', error);
      }
    );
  }

  // ------------------------------------
  // ------- Case Compare Methods -------
  // ------------------------------------

  async applyRevealEffectCompare(caseIds: number[]) {
    var compareUUID = uuidv4();
    this.showDialogBox = true;
    this.activeUUID = compareUUID;
    this.loading = true;

    const newMessage = {
      sentMessage: 'Compare the given cases.',
      resultFromBackend: '',
      loading: true,
      uuid: compareUUID,
      messageType: 'compare',
      stopped: false,
    };
    this.messages.unshift(newMessage);

    this.apiService.compareCases(caseIds).subscribe(
      (response) => {
        this.messages[0].resultFromBackend = response.result;
        this.messages[0].loading = false;

        this.loading = false;
        this.chatHelper.scrollToBottom(this.scrollContainer);
        this.cdRef.detectChanges();
      },
      (error) => {
        console.error('Error during comparison:', error);
        const message = this.messages.find((m) => m.uuid === compareUUID);
        if (message) {
          message.messageType = 'error';
          message.resultFromBackend = 'An error occurred. Please try again.';
          message.loading = false;
          this.stopGeneration();
        }
      }
    );
  }

  async loadPreviousMessages() {
    this.apiService.getCurrentMessages().subscribe(
      (data) => {
        console.log(data);

        let sentMessage = '';
        var uuid = uuidv4();
        this.loading = false;

        if (data.searchResults.publicInfo) {
          const prompt = data.searchResults.publicInfo
            .filter((info: any) => info.role === 'user')
            .map((info: any) => info.content)
            .join('\n');
          sentMessage += prompt;
        }

        const newMessage = {
          sentMessage: sentMessage,
          resultFromBackend: data,
          loading: false,
          uuid: uuid,
          messageType: 'regular',
          stopped: false,
        };

        this.messages.unshift(newMessage);
      },
      (error) => {
        console.error('Failed to load previous messages:', error);
      }
    );
  }

  openGuestPopupForAction(action: string, data?: any): void {
    let message = '';
    let targetRoute = '/chat'; // Default route

    if (action === 'compare' && data) {
      const selectedIds = data.join(',');
      targetRoute = `/compare-cases?caseIds=${selectedIds}`;
      message = 'You must sign in to Docketchat to compare cases.';
    } else if (action === 'viewPdf' && data?.opinionId) {
      targetRoute = `/document-chat?opinionId=${data.opinionId}`;
      message = 'You must sign in to Docketchat to view case documents.';
    }

    this.openGuestPopup(message, targetRoute);
  }

  handleActionWithAuth(action: string, data: any, callback: () => void): void {
    if (!localStorage.getItem('authToken')) {
      this.openGuestPopupForAction(action, data);
    } else {
      callback();
    }
  }

  onActionTriggered(event: { action: string; data?: any }) {
    const { action, data } = event;
    this.handleActionWithAuth(action, data, () => {
      if (action === 'compare') {
        this.compareCases(data);
      } else if (action === 'viewPdf') {
        this.viewPdfDocument(data.opinionId);
      }
    });
  }

  compareCases(caseIds: number[]): void {
    const selectedIds = caseIds.join(',');
    const compareUrl = `/compare-cases?caseIds=${selectedIds}`;
    window.open(compareUrl, '_blank');
  }

  viewPdfDocument(opinionId: number): void {
    const pdfUrl = `/document-chat?opinionId=${opinionId}`;
    window.open(pdfUrl, '_blank');
  }
}
