<div class="popup-overlay">
  <div class="popup-content">
    <!-- <p> You must sign in to Docketchat to access the history features.    </p> -->
    <p>{{ message }}</p>
    <br />
    <button style="margin-right: 10px" (click)="onLogin(targetRoute)">
      Sign In
    </button>
    <button (click)="onHandleGuestPopup()">Continue as Guest</button>
  </div>
</div>
