import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  user: any;
  redirect: string = '/chat';

  constructor(
    public authService: AuthService,
    private meta: Meta,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Get the redirect route from the query params
    this.route.queryParams.subscribe((params) => {
      if (params['redirect']) {
        this.redirect = params['redirect'];
      }
    });

    console.log('redirect route: ', this.redirect);

    // Check if the user is already logged in and fetch user data
    if (this.authService.isLoggedIn()) {
      this.authService.getUser().subscribe(
        (user) => {
          console.log('User Data Retrieved:', user);
          this.user = user;
        },
        (error) => console.error('Not logged in', error)
      );
    }

    // Disable pinch-zoom and ctrl + scroll zoom
    document.addEventListener('wheel', this.preventZoom, { passive: false });
    document.addEventListener('keydown', this.preventZoomKeys, {
      passive: false,
    });
  }

  ngOnDestroy(): void {
    // Re-enable zoom when leaving the page
    document.removeEventListener('wheel', this.preventZoom);
    document.removeEventListener('keydown', this.preventZoomKeys);
  }

  loginWithGoogle(): void {
    console.log('Initiating Google Login');
    this.authService.loginWithGoogle(this.redirect);
  }

  loginWithMicrosoft(): void {
    console.log('Initiating Microsoft Login');
    this.authService.loginWithMicrosoft(this.redirect);
  }

  loginWithApple(): void {
    console.log('Initiating Apple Login');
    this.authService.loginWithApple(this.redirect);
  }

  // Prevent zoom in and zoom out.
  preventZoom(event: WheelEvent): void {
    // Prevent zooming with ctrl + mouse scroll
    if (event.ctrlKey) {
      event.preventDefault();
    }
  }

  preventZoomKeys(event: KeyboardEvent): void {
    // Prevent zooming with ctrl + plus/minus or ctrl + 0
    if (
      event.ctrlKey &&
      (event.key === '+' || event.key === '-' || event.key === '0')
    ) {
      event.preventDefault();
    }
  }
}
