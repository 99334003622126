<div class="fade-in">
  <div
    class="login-container custom-height row justify-content-center align-items-center vh-100"
  >
    <form class="login-form">
      <!-- Signin -->
      <h2 style="color: white">Sign In</h2>
      <br />
      <div style="display: none">
        <p>Sign in to continue</p>
        <input
          type="text"
          placeholder="Username or Email"
          class="input-field"
        />
        <button type="submit" class="next-button">NEXT</button>
        <a href="#" class="forgot-password">Forgot password?</a>
        <div class="divider">or</div>
      </div>
      <!-- Signin with google -->
      <button
        (click)="loginWithGoogle()"
        class="google-signin-button"
        type="button"
      >
        <img src="./images/google.png" alt="Google Icon" class="google-icon" />
        Sign in with Google
      </button>
      <br />
      <button hidden
        (click)="loginWithMicrosoft()"
        class="google-signin-button"
        type="button"
      >
        <img
          src="./images/microsoft.png"
          alt="Google Icon"
          class="google-icon"
        />
        Sign in with Microsoft
      </button>
      <br />
      <button hidden
        (click)="loginWithGoogle()"
        class="google-signin-button"
        type="button"
      >
        <img src="./images/apple.png" alt="Google Icon" class="google-icon" />
        Sign in with Apple
      </button>
    </form>
  </div>
</div>
