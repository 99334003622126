<div class="message-container" [class.loading]="loading">
  <div class="sent-message col-12 d-flex justify-content-end">
    <div
      style="color: #e5e5e5; background-color: #2f2f2f; border-radius: 20px"
      class="p-3 shadow-sm"
    >
      <p class="mb-0" style="max-width: 50dvh">
        {{ sentMessage }}
      </p>
    </div>
  </div>

  <div class="col-12 d-flex justify-content-start">
    <img
      src="/images/logo-no-bg.png"
      alt="Logo"
      class="mb-4"
      style="max-width: 40px"
    />
  </div>

  <!-- Feedback message -->
  <div *ngIf="!stopped && loading" class="loading-indicator">
    <app-feedback-message></app-feedback-message>
  </div>

  <!-- Leave the message blank if its stop before a response arrives. -->
  <div *ngIf="stopped"></div>

  <!-- Regular chat response -->
  <div
    *ngIf="
      !stopped && !loading && resultFromBackend && messageType == 'regular'
    "
    class="result"
  >
    <!-- Display the public info -->
    <div
      *ngIf="
        publicInfoContent &&
        publicInfoContent.trim() !== '' &&
        !publicInfoContent.includes('NOT_FOUND')
      "
      class="fade-in"
      [style.animationDelay]="0 + 's'"
    >
      <h4>Online Resources</h4>
      <div [innerHTML]="publicInfoContent"></div>
    </div>

    <!-- Display the results -->
    <div
      *ngIf="resultFromBackend.searchResults?.searchResult"
      class="fade-in"
      [style.animationDelay]="1 + 's'"
    >
      <h4>Details from Our Records</h4>
    </div>

    <!-- Display the answer -->
    <div
      *ngIf="parsedAnswerContent && parsedAnswerContent.trim() !== ''"
      class="fade-in"
      [style.animationDelay]="1 + 's'"
    >
      <p [innerHTML]="parsedAnswerContent"></p>

      <p>
        Here are the relevant case document(s). You can quickly understand them
        by reading the summaries or interacting with the document directly. To
        compare cases, select the ones you're interested in and click the
        "Compare" button.
        <button (click)="toggleFilter()" class="filter-button-2 link-primary">
          Filter by court name.
        </button>
      </p>
    </div>

    <!-- Display the first 3 searchResults -->
    <div
      *ngIf="resultFromBackend.searchResults?.searchResult"
      class="fade-in"
      [style.animationDelay]="2 + 's'"
    >
      <ol>
        <li
          *ngFor="
            let item of resultFromBackend.searchResults?.searchResult.slice(
              0,
              3
            )
          "
        >
          <p>
            <strong>Case Name:</strong>
            {{ item.case_name || item.case_name_full || "N/A" }}
          </p>
          <p><strong>Court:</strong> {{ item.court_full_name || "N/A" }}</p>
          <p>
            <strong>Date Filed:</strong>
            {{ item.date_filed | date : "shortDate" || "N/A" }}
          </p>
          <p>
            <strong>Docket Number:</strong> {{ item.docket_number || "N/A" }}
          </p>
          <p>
            <strong>Precedential Status:</strong>
            {{ item.precedential_status || "N/A" }}
          </p>
          <p>
            <strong>Summary:</strong>
            <span [innerHTML]="item.parsedShortSummary || 'N/A'"></span>
          </p>
          <p>
            <a [href]="'/document-chat?opinionId=' + item.id" target="_blank"
              >Chat with this document</a
            >
          </p>
        </li>
      </ol>
    </div>
    <!-- Display the table -->
    <div
      *ngIf="resultFromBackend.searchResults?.searchResult"
      class="fade-in"
      [style.animationDelay]="3 + 's'"
    >
      <h4>More Cases...</h4>
      <div class="table-responsive">
        <table
          class="table table-bordered table-hover table-striped table-dark"
        >
          <thead>
            <tr>
              <th>Case Name</th>
              <th>Court</th>
              <th>Date Filed</th>
              <th>Docket Number</th>
              <th>Status</th>
              <th>Summary (Click to Expand)</th>
              <th>PDF</th>
              <th>Compare</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let item of resultFromBackend.searchResults.searchResult;
                let index = index
              "
            >
              <td>
                {{
                  item.case_name ||
                    (item.case_name_full
                      ? (item.case_name_full | slice : 0 : 50) + "..."
                      : "N/A")
                }}
              </td>
              <td>{{ item.court_full_name || "N/A" }}</td>
              <td>{{ item.date_filed | date : "shortDate" || "N/A" }}</td>
              <td>{{ item.docket_number || "N/A" }}</td>
              <td>{{ item.precedential_status || "N/A" }}</td>
              <td
                [title]="item.isTruncated ? item.short_summary : ''"
                (click)="toggleTruncatedContent(item)"
                style="cursor: pointer"
              >
                <span
                  *ngIf="item.isTruncated; else fullSummary"
                  [innerHTML]="item.parsedFullSummary | truncate : 50"
                >
                  >
                </span>
                <ng-template #fullSummary>
                  <span [innerHTML]="item.parsedFullSummary"></span>
                </ng-template>
              </td>

              <td>
                <a
                  (click)="viewPdf(item.id)"
                  style="
                    color: #007bff;
                    text-decoration: underline;
                    cursor: pointer;
                  "
                  >View PDF</a
                >
              </td>
              <td>
                <input
                  type="checkbox"
                  [id]="'checkbox-' + item.id"
                  [checked]="index < 5"
                  (change)="onCheckboxChange($event, item.id)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Compare case response -->
  <div
    *ngIf="
      !stopped && !loading && resultFromBackend && messageType == 'compare'
    "
    class="result fade-in"
  >
    <div [innerHTML]="parsedCompareContent"></div>
  </div>

  <!-- Document chat response -->
  <div
    *ngIf="
      !stopped && !loading && resultFromBackend && messageType == 'document'
    "
    class="result fade-in"
  >
    <div [innerHTML]="parsedDocumentContent"></div>
  </div>

  <!-- Error handling -->
  <div
    *ngIf="!stopped && !loading && resultFromBackend && messageType == 'error'"
    class="result"
  >
    <div [innerHTML]="resultFromBackend"></div>
  </div>

  <!-- Buttons -->
  <div *ngIf="!stopped" class="message-actions d-flex justify-content-end mt-3">
    <button
      *ngIf="selectedCases.length > 1"
      class="compare-button"
      (click)="compareSelectedCases()"
    >
      Compare
    </button>

    <button
      (click)="copyMessage()"
      class="copy-button"
      [disabled]="loading"
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      data-bs-title="Copy"
    >
      <svg>
        <use xlink:href="icons.svg#copy-icon"></use>
      </svg>
    </button>
  </div>
</div>
