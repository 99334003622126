import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // BehaviorSubject to track login state
  private loggedIn = new BehaviorSubject<boolean>(this.isLoggedIn());
  isOAuthStarted = false;

  constructor(private http: HttpClient) {}

  // Expose login state as an observable
  get isLoggedIn$(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  setToken(token: string): void {
    console.log('Storing Token:', token);
    localStorage.setItem('authToken', token);
    this.loggedIn.next(true);
  }

  getToken(): string | null {
    const token = localStorage.getItem('authToken');
    console.log('Retrieved Token:', token);
    return token;
  }

  removeToken(): void {
    console.log('Removing Token');
    localStorage.removeItem('authToken');
    this.loggedIn.next(false);
  }

  getUserFromLocalStorage(): any {
    const token = localStorage.getItem('authToken');
    if (!token) {
      return null;
    }

    try {
      const decodedToken: any = jwtDecode(token);
      console.log(decodedToken);
      return decodedToken;
    } catch (error) {
      console.error('Error decoding JWT:', error);
      return null;
    }
  }

  // Check if the user is logged in by validating the token
  isLoggedIn(): boolean {
    const token = this.getToken();
    if (!token) {
      return false;
    }

    try {
      const decodedToken = jwtDecode(token) as any;
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        console.log('Token has expired.');

        // Send a request to the backend to log the expired token session
        const googleId = decodedToken.googleId;
        this.http.post('/api/expired', { googleId }).subscribe({
          next: () => {
            console.log(
              'Session logged out successfully due to token expiration.'
            );
            this.removeToken();
            window.location.href = '/login';
          },
          error: (error) => {
            console.error(
              'Failed to log out session for expired token:',
              error
            );
          },
        });

        this.removeToken(); // Remove the expired token from localStorage
        return false;
      }

      // Token is valid, user is logged in
      return true;
    } catch (error) {
      console.error('Error decoding token:', error);
      this.removeToken();
      return false;
    }
  }

  loginWithGoogle(redirect: string = '/chat'): void {
    console.log('Initiating Google Login with redirect:', redirect);
    window.location.href = `/api/auth/google?redirect=${encodeURIComponent(
      redirect
    )}`;
  }

  loginWithMicrosoft(redirect: string = '/chat'): void {
    console.log('Initiating Microsoft Login with redirect:', redirect);
    window.location.href = `/api/auth/microsoft?redirect=${encodeURIComponent(
      redirect
    )}`;
  }

  loginWithApple(redirect: string = '/chat'): void {
    console.log('Initiating Apple Login with redirect:', redirect);
    window.location.href = `/api/auth/apple?redirect=${encodeURIComponent(
      redirect
    )}`;
  }

  getUser(): Observable<any> {
    const token = this.getToken();
    if (!token) {
      throw new Error('No token found');
    }
    console.log('Fetching User Data with Token:', token);
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get('/api/user', { headers }).pipe(
      catchError((error) => {
        console.error('Error fetching user data:', error);
        throw error;
      })
    );
  }

  logout(): void {
    const token = this.getToken();
    if (!token) {
      console.log('No token found');
      return;
    }

    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    this.http.get('/api/logout', { headers }).subscribe({
      next: () => {
        console.log('Logout successful, session updated');
        this.removeToken();
        window.location.href = '/login';
        localStorage.removeItem('userProfile');
        // localStorage.removeItem('dateRange');
        localStorage.removeItem('selectedCourts');
      },
      error: (error) => {
        console.error('Logout failed', error);
      },
    });
  }
}
