import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { marked } from 'marked';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
})
export class MessageComponent {
  constructor(private sharedService: SharedService) {}

  @Input() sentMessage: string = '';
  @Input() resultFromBackend: any;
  @Input() loading: boolean = false;
  @Input() uuid: string = '';
  @Input() messageType: string = '';
  @Input() stopped: boolean = false;
  @Output() actionTriggered = new EventEmitter<{
    action: string;
    data?: any;
  }>();

  selectedCases: number[] = [];
  parsedAnswerContent: string = '';
  parsedCompareContent: string = '';
  parsedDocumentContent: string = '';
  publicInfoContent: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['resultFromBackend']) {
      console.log('Updated resultFromBackend:', this.resultFromBackend);
      this.initializeTruncatedFlag();
      this.initializeDefaultCheckedCases();
      this.parseResponse();
    }
  }

  async initializeTruncatedFlag() {
    if (this.resultFromBackend?.searchResults?.searchResult) {
      for (const item of this.resultFromBackend.searchResults.searchResult) {
        if (item.full_summary) {
          item.parsedFullSummary = await marked(item.full_summary);
        }
        if (item.short_summary) {
          item.parsedShortSummary = await marked(item.short_summary);
        }
        item.isTruncated = this.shouldTruncate(item.full_summary);
      }
    }
  }

  copyMessage() {
    let contentToCopy = '';
    // Handle the regular messages
    if (this.messageType == 'regular') {
      // Extract the assistant content from publicInfo
      if (this.resultFromBackend?.searchResults.publicInfo) {
        const assistantContent = this.resultFromBackend.searchResults.publicInfo
          .filter((info: any) => info.role === 'assistant')
          .map((info: any) => info.content)
          .join('\n');
        contentToCopy += 'Public Information:\n' + assistantContent + '\n\n';
      }

      // API response title
      if (this.resultFromBackend?.searchResults?.searchResult) {
        contentToCopy += 'Details from Our Records:\n';
      }

      // Copy the answer if available
      if (this.resultFromBackend?.searchResults.answer) {
        contentToCopy += this.resultFromBackend.searchResults.answer + '\n\n';
      }

      // Copy the search results if available
      if (this.resultFromBackend?.searchResults?.searchResult) {
        contentToCopy += 'Details from Our Records:\n';
        this.resultFromBackend.searchResults.searchResult.forEach(
          (item: any) => {
            contentToCopy += `Case Name: ${item.case_name || 'N/A'}\n`;
            contentToCopy += `Court: ${item.court_full_name || 'N/A'}\n`;
            contentToCopy += `Date Filed: ${item.date_filed || 'N/A'}\n`;
            contentToCopy += `Docket Number: ${item.docket_number || 'N/A'}\n`;
            contentToCopy += `Summary: ${item.short_summary || 'N/A'}\n\n`;
          }
        );
      }
    }
    // Handle Compare Cases Messages
    else if (this.messageType === 'compare') {
      if (this.resultFromBackend) {
        contentToCopy += JSON.stringify(this.resultFromBackend);
      }
    }
    // Handle doc chat message
    else if (this.messageType === 'document') {
      if (this.resultFromBackend?.searchResults) {
        const assistantContent = this.resultFromBackend.searchResults
          .filter((info: any) => info.role === 'assistant')
          .map((info: any) => info.content)
          .join('\n');
        contentToCopy += assistantContent + '\n\n';
      }
    }
    // Create a temporary textarea element to select and copy the content
    const textarea = document.createElement('textarea');
    textarea.value = contentToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  onCheckboxChange(event: any, caseId: number) {
    if (event.target.checked) {
      this.selectedCases.push(caseId);
      console.log(this.selectedCases);
    } else {
      const index = this.selectedCases.indexOf(caseId);
      if (index > -1) {
        this.selectedCases.splice(index, 1);
      }
    }
    if (this.selectedCases.length > 5) {
      this.selectedCases.pop();
      event.target.checked = false;
      this.toggleComparePopup();
    }
  }

  initializeDefaultCheckedCases() {
    if (this.resultFromBackend?.searchResults?.searchResult) {
      this.resultFromBackend.searchResults.searchResult
        .slice(0, 5)
        .forEach((item: any) => {
          if (!this.selectedCases.includes(item.id)) {
            this.selectedCases.push(item.id);
          }
        });
    }
  }

  compareSelectedCases(): void {
    if (this.selectedCases.length > 1) {
      this.actionTriggered.emit({
        action: 'compare',
        data: this.selectedCases,
      });
    }
  }

  viewPdf(opinionId: number): void {
    this.actionTriggered.emit({ action: 'viewPdf', data: { opinionId } });
  }

  shouldTruncate(summary: string): boolean {
    return summary.length > 50;
  }

  toggleTruncatedContent(item: any) {
    item.isTruncated = !item.isTruncated;
  }

  parseResponse() {
    if (this.messageType === 'regular') {
      this.parsePublicResponse();
      this.parseAnswer();
    }
    if (this.messageType === 'compare') {
      this.parseCompareResponse();
    }
    if (this.messageType === 'document') {
      this.parseDocumentResponse();
    }
  }

  async parsePublicResponse() {
    if (this.resultFromBackend?.searchResults?.publicInfo) {
      const assistantContent = this.resultFromBackend.searchResults.publicInfo
        .filter((info: any) => info.role === 'assistant')
        .map((info: any) => info.content)
        .join('\n');
      this.publicInfoContent = await marked(assistantContent);
    }
  }

  async parseAnswer() {
    if (this.resultFromBackend?.searchResults.answer) {
      this.parsedAnswerContent = await marked(
        this.resultFromBackend.searchResults.answer
      );
    }
  }

  async parseCompareResponse() {
    if (this.resultFromBackend) {
      this.parsedCompareContent = await marked(this.resultFromBackend);
    }
  }

  async parseDocumentResponse() {
    if (this.resultFromBackend?.searchResults) {
      const assistantContent = this.resultFromBackend.searchResults
        .filter((info: any) => info.role === 'assistant')
        .map((info: any) => info.content)
        .join('\n');
      this.parsedDocumentContent = await marked(assistantContent);
    }
  }

  toggleFilter() {
    this.sharedService.toggleFilterVisibility();
  }

  toggleComparePopup() {
    this.sharedService.toggleComparePopupVisibility();
  }
}
