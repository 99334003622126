import { Component, Input, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css'],
})
export class PopupComponent {
  @Input() message: string = '';
  @Input() targetRoute: string = '/chat';
  @Input() onHandleGuestPopup!: () => void;
  @Input() onLogin!: (targetRoute: string) => void;
  // @Input() onLogin!: () => void;

  constructor(private meta: Meta) {}

  ngOnInit(): void {
    document.addEventListener('wheel', this.preventZoom, { passive: false });
    document.addEventListener('keydown', this.preventZoomKeys, {
      passive: false,
    });
  }

  ngOnDestroy(): void {
    document.removeEventListener('wheel', this.preventZoom);
    document.removeEventListener('keydown', this.preventZoomKeys);
  }

  preventZoom(event: WheelEvent): void {
    if (event.ctrlKey) {
      event.preventDefault();
    }
  }

  preventZoomKeys(event: KeyboardEvent): void {
    if (
      event.ctrlKey &&
      (event.key === '+' || event.key === '-' || event.key === '0')
    ) {
      event.preventDefault();
    }
  }
}
